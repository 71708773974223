<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="!$route.path.includes('/cocina') && !$route.path.includes('/pizza')" v-model="drawer" mobile-breakpoint="lgAndUp" app dark>
      <v-list lines="one">
        <v-list-group color="#ff0000">
          <v-list-item>
            <template v-slot:prepend>
              <img src="/assets/img/osolemio_logo.png" width="125" />
            </template>
          </v-list-item>
          <v-list-item v-for="(item, i) in itemsPro" :key="i" :to="item.route" dark>
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi-exit-to-app"></v-icon>
            </template>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="font-size:30pt">{{ formattedTime }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view :view="selected" />
    </v-main>
    <v-footer app>
      <span>Omio group&copy; 2024 V 1.0b - Powered by
        <a href="https://grupowapps.es" rel="noopener" target="_blank"
          style="color:#fff;text-decoration: none;font-weight: bold">
          GrupoWapps
        </a>
      </span>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    logged: false,
    isOpen: true,
    selected: 1,
    formattedTime: '--:--',
    itemsPro: [
      { icon: 'mdi-shopping', text: 'Pedidos', route: '/' },
      { icon: 'mdi-clipboard-text', text: 'Activos', route: '/activos' },
      { icon: 'mdi-silverware-variant', text: 'Nuevo pedido', route: '/nuevopedido' },
      { icon: 'mdi-fast-forward', text:'Pedido rápido', route: '/pedidorapido'},
      { icon: 'mdi-calendar-clock', text: 'Histórico', route: '/historial' },
      { icon: 'mdi-clock-fast', text: 'Disponibilidad', route: '/disponibilidad' },
      { icon: 'mdi-table', text: 'Zonas', route: '/zonas' },
      { icon: 'mdi-sync', text: 'Sincronizar', route:'/sync'}
    ],
    itemsBasic: [
      { icon: 'mdi-clipboard-text', text: 'Pedidos', route: '/' },
      { icon: 'mdi-silverware-variant', text: 'Nuevo pedido', route: '/productos' },

      { icon: 'mdi-calendar-clock', text: 'Histórico', route: '/historial' },
      { icon: 'mdi-family-tree', text: 'Carta', route: '/categorias' },
    ],
  }),
  created() {
    this.$vuetify.theme.dark = true;
    this.formattedTime = this.formatTime(new Date())
    setInterval(() => {
      this.formattedTime = this.formatTime(new Date());
    }, 60000);
  },
  methods: {
    formatTime(date) {
      // Obtener horas y minutos
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      // Concatenar horas y minutos con ":"
      return `${hours}:${minutes}`;
    },
    changeStatus() {
      let url = this.base_url + '/updaterestaurantstatus';
      this.axios.post(url, { status: !this.isOpen }, {
        headers: {
          'Authorization': this.$session.get('token')
        }
      }).then(() => {
        this.getStatus()
      })
    },

    getStatus() {
      if (this.$session.exists()) {
        let url = this.base_url + '/getrestaurantstatus';
        this.axios.get(url, {
          headers: {
            'Authorization': this.$session.get('token')
          }
        }).then(response => {
          this.isOpen = response.data.status;
          this.selected = response.data.view;
          this.$session.set('status', response.data.status);
          this.$session.set('view', response.data.view);
        });
      }
    },
    logout() {
      this.$session.destroy();
      this.$router.push('/login');
      location.reload();
    }
  }
}
</script>
<style>
body {
  background: black;
}

.iconView {
  position: fixed !important;
  right: 10vw;
  top: 3vh
}

.statusIcon {
  position: fixed !important;
  right: 4vw;
  top: 2vh
}
</style>
