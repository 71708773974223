import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vue-final-modal/style.css'
import { createVfm } from 'vue-final-modal'
loadFonts()
const vfm = createVfm()
createApp(App)
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(vfm)
  .mount('#app')
