<template>
  <div class="home">
    <ul>
      <order-note v-for="order in orders" :key="order.oid" :order="order" :updateview="getActiveOrders" />
    </ul>
    <!-- <OrderList :orders="orders" :loaded="loaded" v-else/>-->
  </div>
</template>

<script>
import orderNote from '@/components/orders/orderNote'
//import OrderList from "@/components/orders/orderList";
// Components
export default {
  name: 'HomeView',
  props: ['view'],
  components: { orderNote },
  data() {
    return {
      intervalo:null,
      orders: []
    }
  },
 
  mounted() {
    this.getActiveOrders()
    this.intervalo = setInterval(this.getActiveOrders, 5000);
  },
  beforeUnmount() {
    clearInterval(this.intervalo);
  },
  methods: {
    
    getActiveOrders() {
      this.axios.get("https://omioback.grupowapps.es/client/getpickorders").then(response => {
        this.orders = []
        console.log(response)
        let counter = 1
        for (let or of response.data.orders) {
          let name = or.typus == 1 ? or.zone : or.name
          let table = or.typus == 1 ?  "mesa " + or.table : or.phone

          let prodsnow = []
          let prodslater = []
          for (let it of or.items) {
            if (it.forlate)
              prodslater.push({ product: it.name, quantity: it.quantity, later: it.forlate, complements: it.complements })
            else
              prodsnow.push({ product: it.name, quantity: it.quantity, later: it.forlate, complements: it.complements })

          }
          this.orders.push({
            oid: or._id,
            id: 6,
            typus: or.typus,
            number: counter,
            name: name,
            phone: table,
            itemsnow: prodsnow,
            itemslater: prodslater,
            allergens: [],
            amount: or.amount,
            date: or.creationDate,
            status: or.status
          })
          counter++
        }
      })
    }
  }

}
</script>
<style>
.home {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  /* Permite que los elementos se envíen automáticamente a la siguiente fila */
  justify-content: flex-start;
  /* Alinear los elementos a la izquierda */
  list-style: none;
  /* Quitar los puntos de la lista */
  padding: 0;
  margin: 0;
}

h2,
p {
  font-size: 100%;
  font-weight: normal;
}

.home ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0;
  margin: 0;
}

.home li {
  flex: 1 0;
  /* Hacer que los elementos ocupen el mismo ancho */
  padding: 10px;
  margin: 5px;
  /* Espacio entre los elementos */
  min-width: 200px;
  /* Ancho mínimo para evitar que se hagan demasiado estrechos */
}

ul,
li {
  list-style: none;
}

ul {
  overflow: hidden;
  padding: 3em;
}

ul li {
  float: left;
}

ul li h3 {
  font-size: 13pt;
}

ul li h2 {
  font-size: 140%;
  font-weight: bold;
  padding-bottom: 10px;
}

ul li p {
  font-family: "Reenie Beanie", arial, sans-serif;
  font-size: 180%;
}

ul li a {
  text-decoration: none;
  color: #000 !important;
  display: block;
  max-width: 300px;
  padding: 1em;
  -moz-box-shadow: 5px 5px 7px rgba(33, 33, 33, 1);
  -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  -moz-transition: -moz-transform .15s linear;
  -o-transition: -o-transform .15s linear;
  -webkit-transition: -webkit-transform .15s linear;
}



.deliveryNote {
  background: #ffc;
}

.takeawayNote {
  background: #cfc;
}

.restaurantNote {
  background: #ccf;
}



ul li a:hover,
ul li a:focus {
  -moz-box-shadow: 10px 10px 7px rgba(0, 0, 0, .7);
  -webkit-box-shadow: 10px 10px 7px rgba(0, 0, 0, .7);
  box-shadow: 10px 10px 7px rgba(0, 0, 0, .7);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  position: relative;
  z-index: 5;
}
</style>
