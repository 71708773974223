<template>
  <li>
    <router-link :to="'/pedido/' + order.oid"
      v-bind:class="{ 'deliveryNote': order.typus == 2, 'takeawayNote': order.typus == 1, 'restaurantNote': order.typus == 3 }">
      <div class="head">
        <h3 style="display: flex"> #{{ order.number }} </h3>
        <v-divider vertical class="ml-1 mr-1"></v-divider>
        <h3 style="text-align: center;">{{ order.name }} - {{ order.phone }}</h3>
      </div>
      <h3 v-if="order.typus != 1">{{ getFormattedDate(order.date) }} <br> {{ orderStatus }} <v-icon
          v-if="order.status == 0" icon="mdi-archive-outline"></v-icon><v-icon v-if="order.status == 1"
          icon="mdi-stove"></v-icon><v-icon v-if="order.status == 2" icon="mdi-clock-outline"></v-icon></h3>
      <h3 v-else>{{ getFormattedDate(order.date) }}</h3>
      <div>
        <div v-for="prod in order.itemsnow" :key="prod.id">
          <p class="productTitle" style="font-weight: bold">{{ prod.quantity }} x {{ prod.product }} </p>
          <p class="complement mt-1" v-for="complement in prod.complements" :key="complement.name">+ {{ complement.name
            }}
          </p>
        </div>
      </div>
      <hr class="separador" v-if="order.itemslater.length > 0">
      <div v-if="order.itemslater.length > 0">
        <div v-for="prod in order.itemslater" :key="prod.id">
          <p class="productTitle" style="font-weight: bold;">{{ prod.quantity }} x {{ prod.product }} </p>
          <p class="complement mt-1" v-for="complement in prod.complements" :key="complement.name">+ {{ complement.name
            }}
          </p>
        </div>
      </div>
      <h3 style="text-align: center;font-size:16pt" v-if="order.typus != 1">{{ (order.amount) }}€ - {{ paymentTypus
        }}<v-icon v-if="paymentTypus == 'Sin pagar'" icon="mdi-alert-outline" color="orange"></v-icon></h3>
      <v-btn block style="text-align:center; margin-top:1em" variant="outlined" v-if="order.typus != 1">Entregar
        pedido</v-btn>
      <v-btn block style="text-align:center; margin-top:1em" variant="outlined" v-else
        v-on:click="liberarmesa(order.oid)">Liberar mesa</v-btn>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "orderNote",
  props: ['order', 'updateview'],
  data: () => ({
    intervalo: null,
    orderStatus: '',
    paymentTypus: 'pagado'
  }),
  methods: {
    getFormattedDate(fechaISO) {
      const fecha = new Date(fechaISO);

      // Obtener componentes de la fecha
      const dia = fecha.getUTCDate().toString().padStart(2, '0'); // Día (2 dígitos)
      const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0'); // Mes (sumar 1 ya que los meses en JS empiezan en 0)
      const anio = fecha.getUTCFullYear(); // Año

      // Obtener componentes de la hora
      const horas = fecha.getUTCHours().toString().padStart(2, '0'); // Horas (formato 24h)
      const minutos = fecha.getUTCMinutes().toString().padStart(2, '0'); // Minutos
      const segundos = fecha.getUTCSeconds().toString().padStart(2, '0'); // Segundos

      // Formatear la fecha y hora en el formato deseado
      const fechaFormateada = `${dia}/${mes}/${anio} - ${horas}:${minutos}:${segundos}`;

      return fechaFormateada
    },
    liberarmesa(orderid) {
      this.axios.post('https://omioback.grupowapps.es/client/finishorder', { oid: orderid }).then(() => {
        this.updateView()
      })
    }
  },
  mounted() {
    if (this.order.status == 0) {
      this.orderStatus = 'En fornet'
      this.paymentTypus = 'Pagado'
    }

    else if (this.order.status == 1) {
      this.orderStatus = 'En cocina'
      this.paymentTypus = 'Pagado'
    }

    else if (this.order.status == 2) {
      this.orderStatus = 'En cola'
      this.paymentTypus = 'Sin pagar'
    }


  }
}
</script>
<style scoped>
.head {
  display: flex;
  flex-direction: row;
}

.productTitle {
  font-weight: bold;
  font-size: 16pt;
  margin-bottom: 0vh !important;
}

.complement {
  margin: -1vh 0 1vh 1vw !important;
  font-size: 14pt;
}

.separador {
  border: none;
  border-top: 2px dashed black;
  /* Línea punteada */
  height: 2px;
  width: 100%;
  /* Ocupa el 100% del ancho del contenedor */
  margin: 20px 0;
  /* Márgenes opcionales para separación vertical */
}
</style>
