import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/activos',
    name: 'activos',
    component: () => import( '../views/ActiveView.vue')
  },
  {
    path: '/historial',
    name: 'historial',
    component: () => import( '../views/HistoricView.vue')
  },
  {
    path: '/pedido/:id',
    name: 'pedido', 
    component: () => import( '../views/OrderView.vue')
  },
  {
    path: '/editarpedido/:id',
    name: 'editar pedido', 
    component: () => import( '../views/EditOrderView.vue')
  },
  {
    path: '/nuevopedido',
    name: 'nuevo pedido', 
    component: () => import( '../views/NewOrderView.vue')
  },
  {
    path: '/pedidorapido',
    name: 'nuevo pedido rápido', 
    component: () => import( '../views/FastOrderView.vue')
  },
  {
    path: '/cocina',
    name: 'cocina', 
    component: () => import( '../views/KitchenView.vue')
  },
  {
    path: '/pizza',
    name: 'pizza', 
    component: () => import( '../views/PizzaView.vue')
  },
  {
    path: '/zonas',
    name: 'zonas', 
    component: () => import( '../views/ZoneView.vue')
  },
  {
    path: '/disponibilidad',
    name: 'disponibilidad', 
    component: () => import( '../views/SlotsView.vue')
  },
  {
    path: '/sync',
    name: 'sincro', 
    component: () => import( '../views/SyncView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
